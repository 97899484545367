@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap);
@font-face {
  font-family: 'BestermindRegular';
  src: url(/static/media/BestermindRegular.e58a1922.ttf); 
  src: url(/static/media/BestermindRegular.e58a1922.ttf) format('embedded-opentype'), 
       url(/static/media/BestermindRegular.e58a1922.ttf)  format('truetype'), 
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}


/* scrollBar */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #555;
}
::-webkit-scrollbar-thumb {
    background: #555;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

img{
  -webkit-touch-callout: none; 
    -webkit-user-select: none;
        -ms-user-select: none; 
            user-select: none; 

}


@media screen and (max-width: 400px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    body {
      overflow-x: hidden;
    }
}

.navbar {
    height: 6.5rem;
    position: absolute;
    background-color: transparent;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbar--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
    margin-top: 1rem;
}

.navbar--container > h1 {
    font-family: 'BestermindRegular';
    color: var(--primary-2);
    font-size: 2.5rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.navLink--container {
    margin-top: 5rem;
}

.drawer > .MuiBackdrop-root {
    background: rgba(33, 33, 33, 0.15) !important;
    -webkit-backdrop-filter: blur(20px) !important;
            backdrop-filter: blur(20px) !important;
}


@media (max-width:1100px) {
    .navbar--container{
        padding: 0 2rem;
    }
    .navbar--container > h1{
        font-size: 2.2rem;
    }
    .nav-menu{
        font-size: 2.2rem;
        margin-top: -1.5rem;
    }
}

@media (max-width:800px) {
    .navLink--container {
        margin-top: 3rem;
    }
}


@media (max-width:600px) {
    .nav-menu{
        color: var(--primary-2);
    }
    .navbar--container > h1{
        font-size: 2rem;
    }
    .nav-menu:hover{
        color: var(--primary-2);
    }
    .MuiDrawer-paper{
      border-radius: 0 !important;
    }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .navbar {
        height: 5rem;
    }
    .navbar--container {
        padding: 0 1rem;
        margin-top: 0rem;
    }
    .navbar--container > h1 {
        font-size: 1.5rem;
    }
}
.footer {
    font-family: 'var(--primaryFont)', sans-serif;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer p {
    font-weight: 500;
    font-family: var(--primaryFont);
    font-size: 18px;
}

.footer p span {
    font-size: 24px;
}
.landing {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.landing--img {
  --img-size: 400px;
  position: absolute;
  left: 35%;
  transform: translateX(-50%);
  width: var(--img-size);
  height: var(--img-size);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.landing--container-left {
  flex: 35% 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.lcl--content {
  margin: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landing--social {
  font-size: 35px;
  margin: 0 1rem;
  transition: transform 0.5s;
}

.landing--social:hover {
  transform: scale(1.2);
}

.landing--container-right {
  flex: 65% 1;
  height: 100%;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.lcr--content {
  width: 45%;
  margin-right: 7rem;
  font-family: var(--primaryFont);
  font-style: normal;
}

.lcr--content h6 {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: -0.85rem;
  opacity: 0.8;
}

.lcr--content h1 {
  font-weight: 600;
  font-size: 3.25rem;
  line-height: 110%;
  margin: 1rem 0;
}

.lcr--content p {
  margin-top: 1.45rem;
  font-weight: 500;
  font-size: 1.15rem;
  opacity: 0.7;
}

.lcr-buttonContainer {
  margin-top: 2rem;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "var(--primaryFont)", sans-serif;
}

@media (max-width: 1100px) {
  .landing--img {
    --img-size: 350px;
  }
  .lcl--content {
    margin: 2rem;
  }

  .lcr--content h6 {
    font-size: 1.1rem;
  }

  .lcr--content h1 {
    font-size: 3rem;
  }

  .lcr--content p {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
}

@media (max-width: 900px) {
  .landing--img {
    --img-size: 300px;
  }

  .landing--social {
    font-size: 30px;
  }
  .lcr--content {
    width: 60%;
    margin-top: 10%;
    margin-right: 6%;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
  .lcr-buttonContainer button:nth-child(2) {
    display: none;
  }
}

@media (max-width: 600px) {
  .landing--container {
    flex-direction: column;
    justify-content: space-between;
  }
  .landing--img {
    --img-size: 200px;
    left: 50%;
    top: 30%;
    border: 8px solid #eaeaea;
    transform: translate(-50%, -50%);
  }
  .landing--container-left {
    flex: initial;
    width: 100%;
    height: 30%;
  }
  .landing--social {
    display: none;
  }
  .landing--container-right {
    box-sizing: border-box;
    padding: 0 2rem;
    flex: initial;
    height: 70%;
    width: 100%;
    background-color: #eaeaea;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }
  .lcr--content {
    margin-top: 20%;
    margin-right: 0;
    margin-right: initial;
    width: 100%;
  }
  .lcr--content h6 {
    font-size: 1rem;
    text-align: center;
  }
  .lcr--content h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  .lcr--content p {
    font-size: 0.95rem;
    text-align: center;
    margin: 0 auto;
    width: 90%;
  }
  .lcr-buttonContainer {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lcr-buttonContainer button {
    margin-top: -20%;
  }
}

@media (max-width: 500px) {
  .lcr--content {
    margin-top: 35%;
  }
}
@media (max-width: 370px) {
  .lcr--content {
    margin-top: 60%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .landing {
    height: 100%;
  }

  .landing--img {
    --img-size: 150px;
    left: 50%;
    top: 20%;
  }
}

.about {
    min-height: 100vh;
    position: relative;
}

.about .line-styling{
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 85%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about .line-styling .style-circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.about .line-styling .style-line{
  width: 95%;
  height: 5px;
  border-radius: 10px;
}

.about-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 8rem;
    height: -moz-fit-content;
    height: fit-content;
}

.about-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.5 1;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
}

.about-description >h2 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.about-description > p {
    font-size: 1.25rem;
    color: #e0d9d9;
    font-family: var(--primaryFont);
}

.about-img {
    margin-top: 40px;
    pointer-events: none;
}

.about-img > img {
    width: 380px;
    flex: 0.5 1;
}

.tag {
  text-align: center;
}

.slick-slide img {
  margin: auto;
  width: 50%;
  height: 50%;
}

@media (min-width: 992px) and (max-width: 1380px) {

    .about-description > h2 {
      font-size: 3.3rem;
      margin-bottom: 30px;
    }
    .about-description > p {
      font-size: 1.15rem;
    }

    .about-img>img {
        width: 300px;
    } 
}

@media screen and (max-width: 992px){

  .about .line-styling{
    top: 20px;
  }

    .about-body {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }

    .about-description{
        flex: 0.6 1;
        box-sizing: border-box;
        /* padding: 5% 10%; */
        padding: 20px;

    }

    .about-description > h2 {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .about-description > p {
        font-size: 1.1rem;
    }

    .about-img > img {
        width: 250px;
        flex: 0.4 1;
    }

}

@media screen and (max-width: 800px){
  .about .line-styling .style-circle{
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .about-description > h2{
    align-self: center;
  }

}

@media screen and (max-width: 600px){

  .about .line-styling .style-circle{
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .about .line-styling .style-line{
    width: 93%;
    height: 3px;
    border-radius: 10px;
  }

  .about-description > h2 {
    font-size: 2.5rem;
  }

    .about-description > p{
      font-size: 0.95rem;
    }

    .about-img > img {
        width: 200px;
    }
}

.testimonials {
    font-family: var(--primaryFont);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


/* SLIDER STYLES */
.slick-slider {
    height: 100% !important;
    width: 100%;
}


.slick-initialized {
    overflow: hidden;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
}
.slick-dots {
    position: relative !important;
    bottom: 1rem !important;

}


.testimonials--header h1{
    font-style: normal;
    font-weight: bold;
    font-size: 3.5rem;
    text-align: center;
}

.testimonials--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 95%;
    margin-top: 1rem;

    position: relative;

}

.testimonials--body .quote{
    font-size: 4rem;
    position: absolute;
    z-index: 10;

    transform: translateY(50%);

    opacity: 0.5;
}

.testimonials--body button{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;

    -webkit-appearance: none;

            appearance: none;
    outline: none;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.6rem;
}
.testimonials--body .prevBtn{
    left: 5%;
    cursor: pointer;
}
.testimonials--body .nextBtn{
    right: 5%;
    cursor: pointer;
}

.testimonials--slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 70%;
}

.single--testimony {
    padding: 2rem;
    width: 100%;
    height: 500px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.testimonials--container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.review--img{
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: 3;
    transform: translateY(50%);
    box-shadow: 0 0 5px rgba(0,0,0,0.2);

    display: flex;
    align-items: center;
    justify-content: center;

}
.review--img img{
    width: 90px;
}

.review--content{
    margin: 0 auto;
    width: 90%;
    height: 250px;
    border-radius: 200px;

    box-sizing: border-box;
    padding: 30px 40px;
    padding-top: 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.review--content p{
    font-style: italic;
    font-weight: 300;
    width: 80%;
}
.review--content h1{
    font-size: 1.5rem;
    font-weight: 600;

    margin-top: 10px;
}

.review--content h4{
    font-size: 1rem;
    font-weight: 500;
}


@media (max-width: 992px) {

    .testimonials {
        height: 100%;
        padding-bottom: 2rem;
    }

  .testimonials--header h1{
    font-size: 3rem;
    margin-top: 1.75rem;
  }

  .testimonials--body .quote{
      font-size: 3.5rem;
  }


  .review--content{
      border-radius: 50px;
  }
}

@media (max-width: 768px) {

  .testimonials--header h1{
    font-size: 2.5rem;
  }
  .testimonials--body{
    width: 100%;
  }
  .testimonials--body button{
    display: none;
  }
  .testimonials--slider {
    width: 90%;
  }

  .review--content{
      border-radius: 50px;
  }
}

@media (max-width: 576px) {
  .review--img {
    margin: 0;
    margin: initial;
    align-self: flex-start;
  }
  .review--content{
      border-radius: 20px;
      min-height: 300px;
  }
  .review--content p{
      font-size: 0.9rem;
      width: 90%;
  }
  .review--content h1{
      font-size: 1.3rem;
  }

  .review--content h4{
      font-size: 0.9rem;
  }
}

@media (max-width: 360px){
  .testimonials--header h1{
    font-size: 2.2rem;
  }
  .testimonials--body .quote{
      font-size: 3rem;
  }
  .testimonials--slider {
    width: 100%;
  }
.review--img{
    width: 80px;
    height: 80px;
}
.review--img img{
    width: 70px;
}
  .review--content{
      min-height: 350px;
  }
}

.contacts {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}


.contacts--img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 280px;
    pointer-events: none;
}

.contacts--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 6rem 3rem;
    width: 80%;
    height: 100%;
    margin-top: 2rem;
}

.contacts--container > h1 {
    font-family: var(--primaryFont);
    font-size: 3.5rem;
    margin-bottom: 2.5rem;

}

.contacts-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.contacts-form {
    display: flex;
    flex: 0.4 1;
    width: 100%;
}

.contacts-form form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
}

.input-container {
    width: 100%;
}


.form-input {
    height: 50px;
    box-sizing: border-box;
    border-radius: 50px; 
    outline: none;
    margin-bottom: 2rem;
    padding: 0.7rem 0.7rem;
    border-radius: 20px;
    width: 100%; 
    resize: none;
}



.form-message {
    height: 150px;
    box-sizing: border-box;
    border-radius: 50px; 
    outline: none;
    margin-bottom: 2rem;
    padding: 0.7rem 0.7rem;
    border-radius: 20px;
    width: 100%;
    resize: none;
}



.submit-btn button {
    border: none;
    outline: none;
    width: 140px;
    height: 50px;
    border-radius: 50px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.submit-btn button p {
    font-size: 16px;
    font-family: var(--primaryFont);
}

.submit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.send-icon {
    font-size: 25px;
    transform-origin: center;
    transform: translate(5px,-3px) rotate(-30deg);
}

.success-icon {
    font-size: 28px;
    transition: all 0.3s 0.8s ease-in-out;
}

.contacts-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.6 1;
    box-sizing: border-box;
    padding-left: 7rem;
    margin-top: 1.2rem;
}



.personal-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2.5rem;
}

.personal-details p {
    margin-left: 1.5rem;
    font-size: 18px;
    line-height: 110%;
    font-weight: 500;
    font-family: var(--primaryFont);
    width: 45%;
    word-break: break-word;
}

.socialmedia-icons {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: 4rem;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .contacts--img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 240px;
    }
    .contacts--container {
        padding: 2rem 4rem;
        width: 90%;
        margin-top: 1rem;
    }
}


@media screen and (max-width: 992px) {
    .contacts--img {
        display: none;
    }
    .contacts--container {
        width: 100%;
        margin-top: 1rem;
    }
    .contacts--container > h1 {
        font-size: 3rem;
        margin: 0 0 1.2rem 0;
    }
    .contacts-details {
        padding-left: 4rem;
    }
    .personal-details p {
        margin-left: 1rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .contacts--container h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .contacts--container {
        padding: 2rem;
        margin-top: 0rem;
        align-items: center;
    }
    .contacts-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contacts-form {
        display: flex;
        flex: 1 1;
        width: 80%;
    }
    
    .form-input {
        margin-bottom: 0.45rem;
    }
    .contacts-form form {
        align-items: flex-end;
    }
    .contacts-details {
        padding-left: 0rem;
        padding: 1rem;
        align-items: center;
        margin-top: 2rem;
    }
    .socialmedia-icons {
        width: 100%;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        margin-top: 2rem;
    }
    .personal-details {
        margin-bottom: 1.8rem;
    }
    .personal-details p {
        margin-left: 1.2rem;
        width: 95%;
    }
}

@media screen and (max-width: 600px) {
    .contacts-form {
        display: flex;
        flex: 1 1;
        width: 100%;
    }
    .contacts--container h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 400px) {
    .contacts-details {
        padding-left: 0rem;
        padding: 0rem;
        align-items: center;
        margin-top: 2rem;
    }
    .submit-btn{align-self: center;}
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .contacts-details {
        padding-left: 0rem;
        padding: 0rem;
    }
}





@keyframes spin{
    0%{
        opacity: 1;
        transform: translate(5px,-3px) rotate(0deg);
    }
    /* 60%{
      opacity: 0;
    }
    80%{
      opacity: 1;
    } */
    100%{
      transform: rotate(359deg);
    }
  }

  @keyframes fly{
    /* 0%,100%{
        transform: translate(5px,-3px) rotate(-30deg);
        opacity: 1;
    }
    25%{
        transform: translate(15px,-10px) rotate(-30deg);
        opacity: 0.8;
    }
    50%{
        transform: translate(20px,-12px) rotate(-30deg);
        opacity: 0;
    }
    60%{
        transform: translate(-12px,10px) rotate(-30deg);
        opacity: 0;
    }
    75%{
        opacity: 0.8;
    } */
    10%{
        transform: rotate(0deg);
        }
        20%{
        transform: translateX(-10px);    
        }
        70%{
          transform: translateX(60px);
        }
        100%{
            transform: translateX(80px);
        }
  }
.backToTop {
    position: fixed; 
    right: 10px;
    bottom: 60px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        right: -10px;
        bottom: 50px;
        font-size: 2.75rem;
    }
}
:root {
    --primaryFont: 'Poppins', sans-serif;
    /* --primaryFont: 'Montserrat', sans-serif; */
    /* --primaryFont: 'Raleway', sans-serif; */
}


